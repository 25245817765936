import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import loading from './assets/loading.svg';
import './App.scss';


function App() {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const file = window.location.pathname + ".pdf";
  const size = window.innerWidth < 500 ? 600 : window.innerWidth < 820 ? 900 : 1000;

  function onDocumentLoadSuccess({numPages}){
    setNumPages(numPages);
    setPageNumber(1);

    setTimeout(() => {
      setIsLoading(false);
    }, 100)
  }

  function changePage(offSet){
    setPageNumber(prevPageNumber => prevPageNumber + offSet);
  }

  function nextPage(){
    changePage(+1);
  }

  function backPage(){
    changePage(-1);
  }

  return (
    <div className="app">
      {isLoading ? 
        <div className="loading">
          <img src={loading} alt="Carregando ..." />
        </div>
        : null
      }

      {file.length > 5 ? 
        <Document className="document" file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page className="page" width={600} height={800} pageNumber={pageNumber}></Page>
        </Document>
        : null
      }
      { numPages > 1 &&
        <div className="navBar">
            <button onClick={backPage} disabled={!(pageNumber > 1)}>Voltar</button>
            <p>Página {pageNumber} de {numPages}</p>
            <button onClick={nextPage} disabled={!(pageNumber !== numPages)}>Próximo</button>
        </div>
      }
    </div>
  );
}

export default App;
